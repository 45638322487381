import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { IAttribute } from "../interfaces/IAttribute";

interface AttributeTextInputProps {
  selectedAttribute: IAttribute;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
}

const AttributeTextInput = (props: AttributeTextInputProps) => {
  const { selectedAttribute, updateConfiguratorWithUserEnteredValue } = props;

  const [valueString, setValueString] = useState<string>("");
  let tempValueString: string = "";

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    tempValueString += tempValueString + event.target.value;
    setValueString(tempValueString);
  };

  const handleUserEnteredValue = () => {
    updateConfiguratorWithUserEnteredValue(selectedAttribute, valueString);
  };

  return (
    <>
      <Typography>Enter a value for {selectedAttribute.displayText}</Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        flexDirection="row"
        position="relative"
        sx={{ width: "100%", height: "80px", paddingTop: "10px" }}>
        <TextField
          sx={{ width: "100%" }}
          label={selectedAttribute.value ? selectedAttribute.value : "Value"}
          value={valueString}
          onChange={handleValueChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Value"
                  disabled={valueString.length < 1 ? true : false}
                  disableRipple
                  sx={{
                    padding: "7px 22px 7px 22px",
                    borderRadius: "4px",
                    color: "#FFFFFF",
                    backgroundColor: "#2A79C4",
                    width: "62px",
                    height: "32px",
                  }}
                  onClick={() => handleUserEnteredValue()}>
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default AttributeTextInput;
