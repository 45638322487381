import { Box, Typography } from "@mui/material";
import { MissingIcon } from "@rockwell-automation-inc/ra-meatball";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <Box sx={{ margin: "auto", textAlign: "center" }}>
        <MissingIcon sx={{ height: "500px", width: "500px", color: "grey" }}></MissingIcon>
        <Typography>We're sorry ! Your configuration session seems to have timed out.</Typography>
        <Typography>
          Please return to
          <Link to="/"> Product Advisor Home.</Link>
        </Typography>
      </Box>
    </>
  );
};
export default Error;
