import { Container, Typography, Grid, Button, Box, useTheme, Popper, ClickAwayListener, Avatar, CardMedia } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import rawConfigData from "../../configFiles/home-page-cards.json";
import {
  ChevronRightIcon,
  TeaserCard,
  TeaserCardContent,
  TeaserCardMedia,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import { Link as MuiLink } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Masthead from "../../components/masthead/Masthead";
import config from "../../config";
import ROUTES from "../../constants/Routes";
import { GetURLQueryVariable } from "../../util/GetURLQueryVariable";
import React, { useEffect, useState } from "react";
import Aside from "../../components/aside/Aside";
import backgroundImage from "../../components/aside/Background-texture_Red-blue.png";
import { renderToString } from "react-dom/server";
import advisorHomeIcon from "../../assets/products-evaluation.svg";
import { useSelector } from "react-redux";
import { HomePageCardsConfig, Tool, selectCatalog, usePSAppSelector } from "platform-services";
import { documentUrl } from "../../helpers/Utils";

const configData = rawConfigData as unknown as HomePageCardsConfig;

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const thisConfig: any = config;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const catalog = usePSAppSelector(selectCatalog);

  const handleSplashInfo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLink = (launch: string, link: string) => {
    switch (launch) {
      case "InTab":
        handleRoute(link);
        break;
      case "NewTab":
        handleRedirect(link);
        break;
    }
    const homePageProducts = new CustomEvent("homeProductSelection", {
      detail: {
        action: "HomePageSelection",
        properties: {
          category: "WebApp",
          label: "Selected Product",
          product: link,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(homePageProducts);
  };

  const isNormalScreen = useMediaQuery("(min-width: 1105px)");

  const handleSystemDesignerNavigation = (e: React.MouseEvent<HTMLElement>, link: string) => {
    let target = "_self";
    if (e.ctrlKey) {
      target = "_blank";
    }
    window.open(`${link}?projectGuid=${GetURLQueryVariable("projectGuid")}&projectId=${GetURLQueryVariable("projectId")}`, target);
    const tabClick = new CustomEvent("powerControlAdvisorNavigation", {
      detail: {
        action: "Power Control Advisor Navigation",
        properties: {
          category: "WebApp",
          label: "Power Control Click from Home Page",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleRoute = (link: string) => {
    const tempConfig: any = config;
    const path = link.replace(tempConfig.DOMAIN_URL, "");
    navigate(path);
  };

  const handleRedirect = async (link: string) => {
    window.open(link, "_blank");
  };

  const PromotionalTeaserButtonGroup = () => {
    return (
      <>
        <Button variant="outlined" color="white" style={{ fontSize: "1rem" }} onClick={() => window.open(configData.ActionGroups[3].Link, "_blank")}>
          Click Here to Download
        </Button>
      </>
    );
  };

  const displayText: React.ReactNode = (
    <>
      <li>
        New Control System Designer Family Addition – <b>Micro800</b>
        {` `}
        Controller and I/O is now available for configuration.
      </li>
      {` `}
      <li>
        <b>Product Configurator</b> now available for CTO products - {` `} Initial Launch includes <b>Drives</b> Families - access by browsing to
        Drives
      </li>{" "}
      {` `}
      <li>
        <b>Help Me Choose</b> option for <b>Drives Family Selection Assistance</b> - access by browsing to Drives
      </li>{" "}
      {` `}
      <li>
        <b>Product Configurator</b> for CTO Products has now been expanded to include <b>Bulletin 42AF Sensor and 6300M ASEM</b> Products.{" "}
      </li>
    </>
  );

  const displaySubText: React.ReactNode = <li>Product Image Updates throughout - continuous improvement expected</li>;

  useEffect(() => {
    const _displayText = localStorage.getItem("Aside_displayText");
    const _displaySubText = localStorage.getItem("Aside_displaySubText");

    if (!_displayText && displayText) {
      localStorage.setItem("Aside_displayText", renderToString(displayText));
    }

    if (!_displaySubText && displaySubText) {
      localStorage.setItem("Aside_displaySubText", renderToString(displaySubText));
    }

    if (isDisplayTextDifferent()) {
      localStorage.setItem("Aside_displaySubText", renderToString(displaySubText));
      localStorage.setItem("Aside_displayText", renderToString(displayText));
      setButtonChanged(true);
    }
  }, [displayText, displaySubText]);

  const isDisplayTextDifferent = () => {
    const _displayText = localStorage.getItem("Aside_displayText");
    const _displaySubText = localStorage.getItem("Aside_displaySubText");

    return _displayText !== renderToString(displayText) || _displaySubText !== renderToString(displaySubText);
  };

  const [isButtonChanged, setButtonChanged] = useState(isDisplayTextDifferent());

  const splashInfo = () => {
    return (
      <Box display="flex" justifyContent="end">
        {isButtonChanged ? (
          <Button
            onClick={handleSplashInfo}
            sx={{
              width: "auto",
              boxShadow: "0 1px 5px 0 rgb(0 0 0 / 25%)",
              background: "linear-gradient(90deg,rgba(0,0,0,.63) 0,transparent) 50%/cover no-repeat",
              backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 100%), url("${backgroundImage}")`,
            }}>
            <Typography fontWeight="bold" color="#fff">
              What's new
            </Typography>
          </Button>
        ) : (
          <Button onClick={handleSplashInfo} variant="outlined">
            <Typography>What's new</Typography>
          </Button>
        )}
        {open && (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
              setButtonChanged(false);
            }}>
            <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
              <Aside displayText={displayText} displaySubText={displaySubText} />
            </Popper>
          </ClickAwayListener>
        )}
      </Box>
    );
  };

  const products = configData.ActionGroups[0].Tools!.map(x => {
    const product = catalog?.find(c => c.catalogGroupId === x.CatalogGroupId);
    let link =
      product?.selectionAssistanceAvailable === "True"
        ? `${ROUTES.FAMILY_SEARCH}/${product.catalogGroupId}`
        : `${ROUTES.BROWSE}/${product?.catalogGroupId}`;
    link = product ? link : x.Link;

    return {
      ...x,
      Description: product?.title ?? x.Description,
      Icon: (product?.photo ? documentUrl(product.photo) : undefined) ?? x.Icon,
      Link: link,
    } as Tool;
  });

  return (
    <>
      <Masthead isBanner sticky={false} />

      <Container sx={{ mb: 0, mt: 4, pt: 0, pb: 4, pl: 0, pr: 0 }} style={{ backgroundColor: "white" }}>
        {/*<Grid*/}
        {/*  container*/}
        {/*  justifyContent="center"*/}
        {/*  direction="row"*/}
        {/*  alignItems="center"*/}
        {/*  sx={{ pt: 4, mb: 2, width: "100%" }}*/}
        {/*>*/}
        {/*  <Box sx={{ width: "80%" }}>*/}
        {/*    <SearchForm />*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
        {splashInfo()}
        <Grid container direction="column" rowGap={2}>
          <Grid container direction="row" sx={{ py: 4 }} justifyContent="space-between">
            <Grid container direction="column" md={7} sx={{ mb: 2 }}>
              <Box sx={{ mb: 4, display: "flex", alignItems: "flex-end" }}>
                <Typography variant="h4" component="h4" fontWeight="bold" sx={{ pr: 3, lineHeight: 1 }}>
                  {configData.ActionGroups[0].Group}
                </Typography>
                <MuiLink
                  component={Link}
                  className="browse-link"
                  to={ROUTES.BROWSE}
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "500",
                    color: "primary",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}>
                  VIEW ALL
                  <ChevronRightIcon />
                </MuiLink>
              </Box>
              <Grid container direction="row" spacing={2}>
                {products?.map((tool, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={3} justifyContent="center" alignItems="center" key={index}>
                      <TeaserCard
                        sx={{ ml: "auto", mr: "auto", width: "100%", height: 250, py: 2, px: 1 }}
                        onClick={() => handleLink(tool.Launch, tool.Link)}
                        raVariant="vertical">
                        <TeaserCardMedia image={tool.Icon} style={{ backgroundSize: "contain" }} />
                        <TeaserCardContent>
                          <TeaserCardTitle variant="h6" fontWeight="bold" textAlign="center" sx={{ fontSize: "1.2rem" }}>
                            {tool.Description}
                          </TeaserCardTitle>
                        </TeaserCardContent>
                      </TeaserCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" md={4.5} sx={{ mb: 2 }}>
              <Box display="flex" flexDirection="row" sx={{ mb: 4 }}>
                <Typography variant="h4" component="h4" fontWeight="bold" sx={{ lineHeight: 1 }}>
                  {configData.ActionGroups[2].Group}
                </Typography>
              </Box>
              <Grid container direction="column" flexGrow="1" spacing={2} minHeight="20rem">
                {configData.ActionGroups[2].Tools!.map(actionGroup => {
                  return (
                    <Grid item direction="row" md={12} sx={{ height: { xs: "100%", md: "50%" } }}>
                      <TeaserCard
                        sx={{
                          ml: "auto",
                          mr: "auto",
                          display: "flex",
                          textAlign: "center",
                          width: "100%",
                          height: "100%",
                          pt: 12,
                          px: 1,
                        }}
                        onClick={e => {
                          handleSystemDesignerNavigation(e, actionGroup.Link);
                        }}
                        raVariant="vertical">
                        <CardMedia
                          component="img"
                          sx={{
                            height: "120px",
                            backgroundSize: "contain",
                            objectFit: "contain",
                            padding: "10px",
                          }}
                          image={advisorHomeIcon}
                          alt={`Home_photo`}
                        />
                        <TeaserCardContent
                          sx={{
                            maxHeight: "110px !important",
                            minHeight: "110px !important",
                          }}>
                          <TeaserCardTitle variant="h5" paddingBottom="21px" fontWeight="bold">
                            {actionGroup.Tool}
                          </TeaserCardTitle>
                          <TeaserCardSubtitle sx={{ textTransform: "none", paddingBottom: "21px", fontSize: "1rem", px: 10, lineHeight: "22PX" }}>
                            {actionGroup.DisplayText}
                          </TeaserCardSubtitle>
                          <Button
                            onClick={e => {
                              handleSystemDesignerNavigation(e, actionGroup.Link);
                            }}
                            style={{
                              border: "none",
                              background: "none",
                              padding: 0,
                              cursor: "pointer",
                              justifyContent: "flex-end",
                            }}>
                            <Typography color="primary" sx={{ py: 0, px: 0, textTransform: "capitalize" }}>
                              Explore System Designers
                            </Typography>
                          </Button>
                        </TeaserCardContent>
                      </TeaserCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*<TeaserPromotional*/}
        {/*  subtitleText={configData.ActionGroups[3]?.Description || ""}*/}
        {/*  titleText={configData.ActionGroups[3]?.DisplayText || ""}*/}
        {/*  components={<PromotionalTeaserButtonGroup />}*/}
        {/*/>*/}
      </Container>
    </>
  );
};

export default Home;
