import { Box, ButtonBase, CardMedia, FormControlLabel, Typography } from "@mui/material";
import { TeaserCard } from "@rockwell-automation-inc/ra-meatball";
import { IAttribute } from "../interfaces/IAttribute";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import QuantityAdjusterButton from "../../../common/QuantityAdjusterButton/QuantityAdjusterButton";
import { documentUrl } from "../../../../helpers/Utils";

interface AttributeCardProps {
  attribute: IAttribute;
  selectedAttribute: IAttribute;
  handleCardSelect: Function;
  qauntityAdd: Function;
  qauntitySubtract: Function;
}

const AttributeCard = (props: AttributeCardProps) => {
  const { attribute, selectedAttribute, handleCardSelect, qauntityAdd, qauntitySubtract } = props;
  const [imageSrc, setImageSrc] = useState(documentUrl(attribute.thumbnail));
  const [hasQuantity, setHasQuantity] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [showCardOptionOne, setShowCardOptionOne] = useState<boolean>(false);

  const handleSelectClick = (event: any) => {
    if (hasQuantity) {
      event.stopPropagation();
    } else {
      attribute.isSelected = !attribute.isSelected;
      handleCardSelect(attribute);
    }
  };

  const handleImageError = () => {
    setImageSrc(defaultImage);
  };

  const handleQtyAdd = () => {
    qauntityAdd(attribute);
  };

  const handleQtyChange = (qty: Number) => {
    // NO USE FOR THIS FUNCTION. NEED TO DECLARE TO USE COMPONENT
  };

  const handleQtySubtract = () => {
    qauntitySubtract(attribute);
  };

  const isSubtractDisabled = () => {
    if (attribute.qty && attribute.qty === 0) {
      return true;
    }
    if (!attribute.qty && attribute.qty === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const value: boolean =
      ((attribute?.isShowing ?? false) && (attribute?.isAllowed ?? false) && selectedAttribute.class !== "Set") ||
      ((attribute?.isShowing ?? false) && (attribute?.isAllowed ?? false) && selectedAttribute.class === "Set" && hasQuantity);
    setShowCardOptionOne(value);
  }, [attribute, selectedAttribute, hasQuantity]);

  useEffect(() => {
    if (typeof attribute.qty === "number") {
      if (attribute.qty > 0) {
        attribute.isSelected = true;
      } else {
        attribute.isSelected = false;
      }
      setQuantity(attribute.qty);
      setHasQuantity(true);
    }
  }, [attribute]);

  return (
    <>
      {showCardOptionOne && (
        <ButtonBase
          onClick={e => handleSelectClick(e)}
          sx={{ height: "30%", width: { xl: "100%", lg: "100%", md: "100%", sm: "100%", xs: "100%" }, padding: "5px" }}>
          <TeaserCard
            raVariant="horizontal"
            hover={false}
            sx={{
              fontFamily: "inherit",
              borderRadius: "4px",
              width: "100%",
              justifyContent: "space-between",
              gap: "8px",
              border: attribute?.isSelected ? "solid 2px #003E7E" : "",
              padding: "10px",
              boxShadow: "0px 1px 5px 0px #00000040",
              ":hover": {
                boxShadow: "2px 2px 10px 2px #00000040",
              },
            }}>
            {(attribute.thumbnail || (selectedAttribute.class === "Set" && !hasQuantity)) && (
              <Box sx={{ minWidth: "20%", maxWidth: "20%", position: "relative" }}>
                {selectedAttribute.class === "Set" && !hasQuantity && (
                  <FormControlLabel
                    sx={{ position: "absolute", top: -10, left: -0 }}
                    key={attribute.id}
                    control={<Checkbox value={attribute.id} disabled={attribute.isAllowed ? false : true} checked={attribute.isSelected} />}
                    label=""></FormControlLabel>
                )}
                {attribute.thumbnail && (
                  <CardMedia
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                    component="img"
                    image={imageSrc}
                    onError={handleImageError}
                    alt="component_img"
                  />
                )}
              </Box>
            )}
            <Box sx={{ display: "flex" }}>
              <Typography>{attribute.displayText}</Typography>
            </Box>
            <Box sx={{ padding: "0px", display: "flex", justifyContent: "end" }}>
              {hasQuantity && (
                <Box>
                  <QuantityAdjusterButton
                    value={quantity}
                    handleQtyAdd={() => handleQtyAdd()}
                    handleQtyChange={e => handleQtyChange(Number(e))}
                    handleQtySubtract={() => handleQtySubtract()}
                    subtractDisabled={isSubtractDisabled()}
                  />
                </Box>
              )}
            </Box>
          </TeaserCard>
        </ButtonBase>
      )}
      {attribute?.isShowing && selectedAttribute?.class === "Set" && !hasQuantity && (
        <ButtonBase
          disabled={!attribute?.isAllowed}
          onClick={e => handleSelectClick(e)}
          sx={{ height: "30%", width: { xl: "100%", lg: "100%", md: "100%", sm: "100%", xs: "100%" }, padding: "5px" }}>
          <TeaserCard
            raVariant="horizontal"
            hover={false}
            sx={{
              fontFamily: "inherit",
              borderRadius: "4px",
              justifyContent: "center",
              width: "100%",
              gap: "8px",
              border: attribute?.isSelected ? "solid 2px #003E7E" : "",
              padding: "10px",
              boxShadow: "0px 1px 5px 0px #00000040",
              ":hover": {
                boxShadow: "2px 2px 10px 2px #00000040",
              },
            }}>
            <Box
              sx={{
                minWidth: hasQuantity ? "70%" : "100%",
                height: "100%",
              }}>
              <Box sx={{ display: "flex", alignItems: "center", minHeight: "20%", maxHeight: "20%", maxWidth: hasQuantity ? "70%" : "100%" }}>
                {selectedAttribute.class === "Set" && !hasQuantity && (
                  <FormControlLabel
                    sx={{ postion: "absolute" }}
                    key={attribute.id}
                    control={<Checkbox value={attribute.id} disabled={attribute.isAllowed ? false : true} checked={attribute.isSelected} />}
                    label=""></FormControlLabel>
                )}
                <Typography>{attribute.displayText}</Typography>
              </Box>
              <Box
                sx={{
                  maxHeight: "70%",
                  maxWidth: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  overflow: "hidden",
                  marginTop: "10px",
                }}>
                {attribute.thumbnail && (
                  <CardMedia
                    sx={{
                      maxWidth: "50%",
                      maxHeight: "50%",
                      objectFit: "contain",
                    }}
                    component="img"
                    image={imageSrc}
                    onError={handleImageError}
                    alt="component_img"
                  />
                )}
              </Box>
            </Box>
            {hasQuantity && (
              <Box sx={{ maxWidth: "30%", padding: "0px" }}>
                <QuantityAdjusterButton
                  value={attribute.qty ? attribute.qty : 0}
                  handleQtyAdd={() => handleQtyAdd()}
                  handleQtyChange={e => handleQtyChange(Number(e))}
                  handleQtySubtract={() => handleQtySubtract()}
                  subtractDisabled={isSubtractDisabled()}
                />
                test2
              </Box>
            )}
          </TeaserCard>
        </ButtonBase>
      )}
    </>
  );
};

export default AttributeCard;
