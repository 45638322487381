import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SearchProductAttribute from "../../interfaces/SearchProductAttribute";
import SearchProductAttributeGroup from "../../interfaces/SearchProductAttributeGroup";
import SearchCriteriaAttributes from "../../interfaces/SearchCriteriaAttributes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputAdornment,
  Switch,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FlagIcon, InformationCircleIcon } from "@rockwell-automation-inc/ra-meatball";
import { PricingAndAvailability } from "../../interfaces/PricingAndAvailability";
import { useEffect, useRef, useState } from "react";
import PricingInput from "./PricingInput";
import SearchIcon from "@mui/icons-material/Search";
import SearchSidebarLoader from "../searchLoader/SearchSidebarLoader";
import React from "react";
import { CatalogProduct, CatalogTemplate, selectUser, usePSAppSelector } from "platform-services";
import { selectActiveFilters, setActiveFilters as setActiveFiltersState } from "../../redux/slices/activeFiltersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import "./SearchSidebar.scss";

interface SearchSidebarProps {
  searchCriteriaGroupTids: Array<CatalogProduct>;
  searchCriteriaAttributes: SearchCriteriaAttributes;
  pricingAndAvailability: PricingAndAvailability;
  handleTemplateChange: Function;
  handleFilterChange: Function;
  handlePriceAndAvailabilityChange: Function;
  tids: string;
  setTids?: Function;
  searchCriteria: string;
  catalogTreeLoading: boolean;
  groupId?: string;
  enableGuidedSelection?: boolean;
  setEnableGuidedSelection?: Function;
}

const SearchSidebar = ({
  searchCriteriaGroupTids,
  searchCriteriaAttributes,
  pricingAndAvailability,
  handleTemplateChange,
  handleFilterChange,
  handlePriceAndAvailabilityChange,
  tids,
  setTids,
  searchCriteria,
  catalogTreeLoading,
  groupId,
  enableGuidedSelection,
  setEnableGuidedSelection,
}: SearchSidebarProps) => {
  const dispatch = useAppDispatch();
  const [activeFilters, setActiveFilters] = [
    useAppSelector(selectActiveFilters),
    (x: Record<string, string[]>) => dispatch(setActiveFiltersState(x)),
  ];

  const user = usePSAppSelector(selectUser);
  const isClearAll = () => {
    return (
      Object.keys(activeFilters).length > 0 ||
      pricingAndAvailability.readyToShip ||
      (pricingAndAvailability.maxListPriceUserInputDisplay === pricingAndAvailability.maxListPriceUserInputDisplay &&
        pricingAndAvailability.maxListPriceUserInputDisplay) ||
      pricingAndAvailability.maxAvailability !== null ||
      (searchCriteriaGroupTids.filter(i => i.childGroups.find(k => k.templates.length > 1)).length > 0 && tids !== "")
    );
  };

  const theme = useTheme();

  const [pricingAndAvailabilityState, setPricingAndAvailabilityState] = useState<PricingAndAvailability>({
    ...pricingAndAvailability,
    minPrice: pricingAndAvailability.minPrice ?? pricingAndAvailability.defaultMinPrice,
    maxPrice: pricingAndAvailability.maxPrice ?? pricingAndAvailability.defaultMaxPrice,
  });

  useEffect(() => {
    setPricingAndAvailabilityState({
      ...pricingAndAvailability,
      minPrice: pricingAndAvailability.minPrice !== null ? pricingAndAvailability.minPrice : pricingAndAvailability.defaultMinPrice,
      maxPrice: pricingAndAvailability.maxPrice !== null ? pricingAndAvailability.maxPrice : pricingAndAvailability.defaultMaxPrice,
    });
  }, [pricingAndAvailability]);

  const handleFilterCheck = (event: React.ChangeEvent<HTMLInputElement>, name: string, attribute: SearchProductAttribute) => {
    handleFilterChange(name, attribute, event.target.checked);
  };

  const handleGuidedSelectionSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEnableGuidedSelection) {
      setEnableGuidedSelection(event.target.checked);
    }

    const tabClick = new CustomEvent("guidedSelectionSwitchClick", {
      detail: {
        action: "Guided Selection Switch Click",
        properties: {
          category: "WebApp",
          label: "Guided Selection Switch",
          country: searchCriteriaAttributes.userCountryName,
          switchStatus: event.target.checked,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleClearAll = () => {
    if (pricingAndAvailability.readyToShip || pricingAndAvailability.maxListPriceUserInputDisplay !== "" || pricingAndAvailability.maxAvailability) {
      handlePriceAndAvailabilityChange({
        minPrice: null,
        maxPrice: null,
        defaultMinPrice: null,
        defaultMaxPrice: null,
        minListPriceDisplay: null,
        maxListPriceDisplay: null,
        readyToShip: false,
        isReadyToShip: false,
        maxAvailability: null,
        maxListPriceUserInputDisplay: "",
      });
    }

    if (searchCriteriaAttributes.productAttributes.find(i => i.values.find(k => !k.isGuidedSelection && k.isSelected))) {
      setActiveFilters({});
    }

    if (searchCriteriaGroupTids.find(i => i.childGroups.find(k => k.templates.length > 1)) && tids !== "" && setTids) {
      setTids("");
    }

    const tabClick = new CustomEvent("clearAllFiltersClick", {
      detail: {
        action: "Clear All Filters Click",
        properties: {
          category: "WebApp",
          label: "Clear All Filters",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleTemplateCheck = (event: React.ChangeEvent<HTMLInputElement>, templateId: string) => {
    handleTemplateChange(templateId, event.target.checked);
  };

  const handleMinPriceChange = (minPrice: number) => {
    if (pricingAndAvailability.minPrice === null && minPrice === pricingAndAvailability.minListPrice) return;
    else if (pricingAndAvailability.minPrice === minPrice) return;

    const pricingAndAvailabilityCopy: PricingAndAvailability = {
      ...pricingAndAvailabilityState,
    };

    if (minPrice > 0) {
      pricingAndAvailabilityCopy.minPrice = minPrice;
    } else {
      pricingAndAvailabilityCopy.minPrice = null;
    }

    setPricingAndAvailabilityState(pricingAndAvailabilityCopy);

    handlePriceAndAvailabilityChange(pricingAndAvailabilityCopy);
  };

  const handleMaxPriceChange = (maxPrice: number) => {
    if (pricingAndAvailability.maxPrice === null && maxPrice === pricingAndAvailability.maxListPrice) {
      return;
    } else if (pricingAndAvailability.maxPrice === maxPrice) {
      return;
    }

    if (maxPrice !== pricingAndAvailability.maxPrice) {
      const pricingAndAvailabilityCopy: PricingAndAvailability = {
        ...pricingAndAvailabilityState,
      };

      if (maxPrice > 0) {
        pricingAndAvailabilityCopy.maxPrice = maxPrice;
      } else {
        pricingAndAvailabilityCopy.maxPrice = null;
      }

      setPricingAndAvailabilityState(pricingAndAvailabilityCopy);

      handlePriceAndAvailabilityChange(pricingAndAvailabilityCopy);
    }
    const tabClick = new CustomEvent("priceChange", {
      detail: {
        action: "User Entered Price",
        properties: {
          category: "WebApp",
          label: "User Entered Price",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleReadyToShipChange = () => {
    const pricingAndAvailabilityCopy: PricingAndAvailability = {
      ...pricingAndAvailabilityState,
    };

    pricingAndAvailabilityCopy.readyToShip = !pricingAndAvailabilityCopy.readyToShip;

    if (pricingAndAvailabilityCopy.readyToShip) pricingAndAvailabilityCopy.maxAvailability = null;

    setPricingAndAvailabilityState(pricingAndAvailabilityCopy);

    handlePriceAndAvailabilityChange(pricingAndAvailabilityCopy);

    const label: string = pricingAndAvailabilityCopy.readyToShip ? " In-Stock chip added" : "In-Stock chip deleted";

    const tabClick = new CustomEvent("inStockChipAction", {
      detail: {
        action: "In-Stock Chip Action",
        properties: {
          category: "WebApp",
          label: label,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleMaxAvailabilityChange = (availability: number) => {
    const pricingAndAvailabilityCopy: PricingAndAvailability = {
      ...pricingAndAvailabilityState,
    };

    pricingAndAvailabilityCopy.maxAvailability = availability;

    setPricingAndAvailabilityState(pricingAndAvailabilityCopy);

    handlePriceAndAvailabilityChange(pricingAndAvailabilityCopy);
    const tabClick = new CustomEvent("userEnteredLeadTime", {
      detail: {
        action: "User Entered Lead Time",
        properties: {
          category: "WebApp",
          label: "User Entered Lead Time",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const isValidPricingChange = (prevValue: number | null | undefined, newValue: number) => {
    return prevValue !== newValue || (prevValue !== null && newValue === 0);
  };

  const getMinPrice = () => {
    if (pricingAndAvailabilityState.minPrice) {
      return pricingAndAvailabilityState.minPrice;
    } else if (pricingAndAvailability.minListPrice) {
      return pricingAndAvailability.minListPrice;
    }
    return 0;
  };

  const getMaxPrice = () => {
    if (pricingAndAvailabilityState.maxPrice) {
      return pricingAndAvailabilityState.maxPrice;
    } else if (pricingAndAvailability.maxListPrice) {
      return pricingAndAvailability.maxListPrice;
    }
    return 0;
  };

  const getDisplayPrice = (userInput: string, maxDisplay: string) => {
    if (userInput !== "" && userInput) {
      return userInput;
    } else if (maxDisplay) {
      return maxDisplay;
    }
    return "";
  };

  return (
    <>
      <Accordion defaultExpanded={false} sx={{ ".MuiAccordionDetails-root": { py: 0 } }}>
        <AccordionSummary
          expandIcon={null}
          aria-controls={`group-tids-content`}
          id={`group-tids-header`}
          style={{ fontWeight: "bold" }}
          sx={{ mb: 2, overflow: "auto", mt: 2 }}>
          Filter and Refine
          {isClearAll() && (
            <Button sx={{ mt: -0.5, ml: 16 }} style={{ border: "none", background: "none", color: "blue" }} size="small" onClick={handleClearAll}>
              Clear All
            </Button>
          )}
        </AccordionSummary>
      </Accordion>
      {user && (
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`group-tids-content`}
            id={`group-tids-header`}
            style={{ fontWeight: "bold" }}>
            Guided Selection
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: "20rem",
              overflow: "auto",
              padding: "8px 4px 8px 8px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}>
            <FlagIcon sx={{ width: "35px", height: "20px" }} />{" "}
            <Typography sx={{ lineHeight: "22px", fontSize: " 16px", fontWeight: "500" }}>{searchCriteriaAttributes.userCountryName}</Typography>
          </AccordionDetails>
          <AccordionDetails
            sx={{
              maxHeight: "20rem",
              overflow: "auto",
              display: "flex",
              gap: "30px",
              py: "4px",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "14px",
              lineHeight: "18px",
              fontWeight: 400,
            }}>
            Location Based Results
            {enableGuidedSelection !== undefined && setEnableGuidedSelection && (
              <Switch
                checked={enableGuidedSelection}
                onChange={handleGuidedSelectionSwitch}
                sx={{
                  ".MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { backgroundColor: theme.palette.purple.main },
                }}
              />
            )}
          </AccordionDetails>
          <AccordionDetails sx={{ display: "flex", gap: 1, flexDirection: "column", py: "4px", mb: 2 }}>
            <Typography sx={{ color: theme.palette.mediumGrey.main, fontSize: "12px", lineHeight: "18px" }}>
              {" "}
              Location Based Results are a quick way to see results based on your location.
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}

      {catalogTreeLoading && <SearchSidebarLoader size={1} />}
      {!catalogTreeLoading &&
        searchCriteriaGroupTids.find(c => c.showCatalogGroup) &&
        searchCriteriaGroupTids.find(i => i.childGroups.find(k => k.templates.length > 1)) && (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`group-tids-content`}
              id={`group-tids-header`}
              style={{ fontWeight: "bold" }}>
              Product Categories
            </AccordionSummary>
            <AccordionDetails id={`group-tids-content`} sx={{ maxHeight: "20rem", overflow: "auto", padding: "10px", marginBottom: "2rem" }}>
              {searchCriteriaGroupTids
                .filter(c => c.showCatalogGroup)
                .map((template: CatalogProduct, index: number) => {
                  return (
                    template.showCatalogGroup && (
                      <ProductFamily
                        key={Math.random()}
                        groupTemplate={template}
                        index={index}
                        handleTemplateCheck={handleTemplateCheck}
                        tids={tids}
                        searchCriteria={searchCriteria}
                        groupId={groupId}
                      />
                    )
                  );
                })}
            </AccordionDetails>
          </Accordion>
        )}

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`group-pricing-content`}
          id={`group-pricing-header`}
          style={{ fontWeight: "bold" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <Box>Pricing & Availability</Box>
            <Tooltip
              title={
                <Box
                  sx={{
                    color: theme.palette.mediumGrey.main,
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    fontSize: "12px",
                    lineHeight: "18px",
                    p: 1,
                  }}>
                  <span>
                    Advisor pulls daily updates reviewing stocking statuses from local Rockwell Automation distribution centers to provide the most
                    accurate availability information.If a product is flagged as “in stock,” the product is available today AND can be shipped from a
                    RA distribution center shortly.
                  </span>
                  <span>
                    The lead time estimates, which are calculated in calendar days, consider delays caused by ongoing supply constraints stemming from
                    components shortages, raw material supply, and related issues. These estimates are a snapshot in time and should be used for
                    planning purposes only. Once an order is placed, the lead time will be provided based on inventory availability, backlog, and
                    supply schedule.
                  </span>
                  <span>Please reach out to your local Rockwell Automation distributor for additional information.</span>
                </Box>
              }>
              <Box>
                <InformationCircleIcon />
              </Box>
            </Tooltip>
          </Box>
        </AccordionSummary>
        <AccordionDetails id={`group-pricing-content`} sx={{ maxHeight: "18rem", overflow: "auto", padding: "10px" }}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 2, px: 0 }}>
            <Tooltip placement="top" arrow title={!user ? "Log in to see Pricing and Lead Time" : ""}>
              <Box sx={{ pl: 1, width: "40%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => handleReadyToShipChange()}
                        checked={pricingAndAvailabilityState.readyToShip}
                        disabled={!pricingAndAvailabilityState.isReadyToShip || !user}
                        sx={{ padding: "5px" }}
                      />
                    }
                    label={<Typography style={{ fontSize: ".9rem" }}>In-Stock</Typography>}
                  />
                </FormControl>
              </Box>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 2, px: 0 }}>
            <Tooltip placement="top" arrow title={!user ? "Log in to see Pricing and Lead Time" : ""}>
              <Box sx={{ width: "100%" }}>
                <AvailabilityInput
                  disabled={!user}
                  pricingAndAvailability={pricingAndAvailability}
                  pricingAndAvailabilityState={pricingAndAvailabilityState}
                  handleMaxAvailabilityChange={handleMaxAvailabilityChange}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 2, px: 0 }}>
            <Tooltip placement="top" arrow title={!user ? "Log in to see Pricing and Lead Time" : ""}>
              <Box style={{ width: "100%" }}>
                <PricingInput
                  value={getMaxPrice() || 0}
                  label="List Price Maximum"
                  disabled={!user}
                  displayValue={getDisplayPrice(
                    pricingAndAvailability.maxListPriceUserInputDisplay || "",
                    pricingAndAvailability.maxListPriceDisplay || ""
                  )}
                  onBlur={e => {
                    if (isValidPricingChange(pricingAndAvailability.maxPrice, Number(e.target.value))) {
                      handleMaxPriceChange(Number(e.target.value));
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </AccordionDetails>
      </Accordion>
      {searchCriteriaAttributes.productAttributes.map((group, idx) => (
        <ProductAttributeGroup group={group} index={idx} key={idx} handleFilterCheck={handleFilterCheck} />
      ))}
    </>
  );
};

interface AvailabilityInputProps {
  disabled?: boolean;
  pricingAndAvailability: PricingAndAvailability;
  pricingAndAvailabilityState: PricingAndAvailability;
  handleMaxAvailabilityChange: (value: number) => void;
}

const AvailabilityInput = ({
  disabled = false,
  pricingAndAvailability,
  pricingAndAvailabilityState,
  handleMaxAvailabilityChange,
}: AvailabilityInputProps) => {
  const [maxAvailability, setMaxAvailability] = useState<number | null | undefined>(
    pricingAndAvailabilityState.maxAvailability ? pricingAndAvailabilityState.maxAvailability : pricingAndAvailabilityState.maxPublishedLeadTime
  );

  const getMaxAvailability = () => {
    if (pricingAndAvailabilityState.readyToShip) {
      return 5;
    }
    return maxAvailability || pricingAndAvailabilityState.maxPublishedLeadTime;
  };

  return (
    <FormControl fullWidth>
      <TextField
        id="availability-max-amount"
        label="Lead Time Maximum (Estimated)"
        variant="outlined"
        type={"number"}
        InputLabelProps={{ shrink: true }}
        value={!disabled ? getMaxAvailability() : 0}
        disabled={pricingAndAvailability.readyToShip || disabled}
        onChange={e => setMaxAvailability(Number(e.target.value))}
        onBlur={e => handleMaxAvailabilityChange(Number(e.target.value))}
        onFocus={e => e.target.select()}
      />
    </FormControl>
  );
};

interface ProductFamilyProps {
  groupTemplate: CatalogProduct;
  index: number;
  offset?: number;
  isRootParent?: boolean;
  handleTemplateCheck: (event: React.ChangeEvent<HTMLInputElement>, templateId: string) => void;
  tids: string;
  searchCriteria: string;
  groupId?: string;
}

const ProductFamily = ({
  groupTemplate,
  index,
  offset,
  handleTemplateCheck,
  tids,
  searchCriteria,
  groupId,
  isRootParent = true,
}: ProductFamilyProps) => {
  return (
    <>
      {groupTemplate.showCatalogGroup && (
        <>
          {isRootParent && (
            <Typography fontWeight="900" sx={{ py: 1, pl: 1 + (offset || 0) }} className="group-template__header">
              {groupTemplate.title}
            </Typography>
          )}
          {!isRootParent && <Typography sx={{ py: 1, pl: 1 + (offset || 0) }}>&ndash; {groupTemplate.title}</Typography>}
          {groupTemplate.childGroups.length > 0 &&
            groupTemplate.childGroups.map((child, idx) => (
              <ProductFamily
                key={Math.random()}
                groupTemplate={child}
                index={idx}
                offset={(offset || 0) + 1}
                isRootParent={false}
                handleTemplateCheck={handleTemplateCheck}
                tids={tids}
                searchCriteria={searchCriteria}
                groupId={groupId}
              />
            ))}
          {groupTemplate.templates.length > 0 &&
            groupTemplate.templates.map((template: CatalogTemplate, index: number) => {
              return (
                <Box sx={{ pl: (offset || 0) + 4 }} key={Math.random()}>
                  <FormControl key={index} fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => handleTemplateCheck(event, template.templateId)}
                          checked={!!tids.split(",").find(v => v === template.templateId)}
                          disabled={searchCriteria === "" && (groupId === "All" || !groupId) && tids.split(",").length === 1}
                          sx={{ padding: "5px" }}
                        />
                      }
                      label={<Typography style={{ fontSize: ".9rem" }}>{template.title}</Typography>}
                    />
                  </FormControl>
                </Box>
              );
            })}
        </>
      )}
      {!groupTemplate.showCatalogGroup && (
        <>
          {groupTemplate.templates.length > 0 &&
            groupTemplate.templates.map(template => {
              return (
                <Box sx={{ pl: offset ? offset + 4 : 2 }} key={Math.random()}>
                  <FormControl key={index} fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => handleTemplateCheck(event, template.templateId)}
                          checked={!!tids.split(",").find(v => v === template.templateId)}
                          disabled={searchCriteria === "" && (groupId === "All" || !groupId) && tids.split(",").length === 1}
                          sx={{ padding: "5px" }}
                        />
                      }
                      label={<Typography style={{ fontSize: ".9rem" }}>{template.title}</Typography>}
                    />
                  </FormControl>
                </Box>
              );
            })}
        </>
      )}
    </>
  );
};

interface SearchBarProps {
  index: number;
  searchTerm: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const SearchBar = ({ index, searchTerm, handleChange }: SearchBarProps) => {
  return (
    <FormControl style={{ width: "98%" }}>
      <TextField
        id={`product-attribute-group-${index}`}
        label={"Filter Tags"}
        variant="outlined"
        type="text"
        value={searchTerm}
        onChange={e => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          id: `product-attribute-group-input-${index}`,
        }}
      />
    </FormControl>
  );
};

interface ProductAttributeGroupProps {
  group: SearchProductAttributeGroup;
  index: number;
  handleFilterCheck: (event: React.ChangeEvent<HTMLInputElement>, groupName: string, attribute: SearchProductAttribute) => void;
}

const ProductAttributeGroup = ({ group, index, handleFilterCheck }: ProductAttributeGroupProps) => {
  const dispatch = useAppDispatch();
  const [activeFilters, setActiveFilters] = [
    useAppSelector(selectActiveFilters),
    (x: Record<string, string[]>) => dispatch(setActiveFiltersState(x)),
  ];
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm]);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`group-${index}-content`}
        id={`group-${index}-header`}
        style={{ fontWeight: "bold" }}>
        {group.name}
      </AccordionSummary>
      <AccordionDetails id={`group-${index}-content`} sx={{ maxHeight: "14rem", overflow: "auto" }}>
        {group.values.length >= 20 && <SearchBar index={index} searchTerm={searchTerm} handleChange={handleChange} />}
        {group.values
          .filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((attribute: SearchProductAttribute, index: number) => {
            const checkboxStyle: SxProps<Theme> = attribute.isGuidedSelection
              ? {
                  // hovered checkbox
                  "input:hover ~ span": {},
                  // checkbox with keyboard focus
                  "input:focus ~ span": {},
                  // checked checkbox
                  "&.Mui-checked input ~ span": {
                    backgroundColor: theme.palette.purple.main,
                    boxShadow: `0 0 0 1px ${theme.palette.secondary.main} inset`,
                  },
                  py: 0.8,
                }
              : { py: 0.8 };

            const isSelected = attribute.isGuidedSelection
              ? attribute.isSelected
              : activeFilters[group.name]?.find(x => x === attribute.name) !== undefined;

            return (
              <FormControl key={index} fullWidth>
                <FormControlLabel
                  control={<Checkbox onChange={event => handleFilterCheck(event, group.name, attribute)} checked={isSelected} sx={checkboxStyle} />}
                  label={
                    <Box display="flex" style={{ fontSize: ".9rem" }}>
                      <Typography color="black">{attribute.name} &nbsp;</Typography>
                      <Typography color="grey">({attribute.productCount})</Typography>
                    </Box>
                  }
                />
              </FormControl>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchSidebar;
