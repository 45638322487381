import { useState, useEffect, CSSProperties } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, CardMedia, Container, Typography } from "@mui/material";
import LayoutType from "../../constants/LayoutType";
import ROUTES from "../../constants/Routes";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { documentUrl, findNestedProduct } from "../../helpers/Utils";
import SearchForm from "../../components/searchForm/SearchForm";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { useMediaQuery } from "@mui/material";
import { TeaserCard, TeaserCardContent, TeaserCardSubtitle, TeaserCardTitle, ChevronDownIcon } from "@rockwell-automation-inc/ra-meatball";
import Masthead from "../../components/masthead/Masthead";
import defaultImage from "../../assets/Image_Unavailable.svg";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import LayoutSwitchButton from "../../components/LayoutSwitchButton/LayoutSwicthButton";
import ConfiguratorButton from "../../components/rockwellConfigurator/components/configuratorButton/ConfiguratorButton";
import { CatalogProduct, CatalogTemplate, FeatureFlag, selectCatalog, usePSAppSelector } from "platform-services";
import FEATURES from "../../constants/FeatureFlags";

const Browse = () => {
  //useParams for catalogGroupId located in LayoutView.tsx
  const { catalogGroupId } = useParams();
  const location = useLocation();
  const catalog = usePSAppSelector(selectCatalog);
  const [loading, setLoading] = useState(true);
  const [currentLayout, setCurrentLayout] = useState<string>(() => sessionStorage.getItem("activeCardLayout") || LayoutType.Grid);
  const [currentProduct, setCurrentProduct] = useState<CatalogProduct>();
  const navigate = useNavigate();
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);
  const [displayConfigButton, setDisplayConfigButton] = useState<boolean>(false);

  useEffect(
    () => {
      loadCurrentProduct();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, catalogGroupId, catalog]
  );

  const handleLayoutChange = (layout: string) => {
    setCurrentLayout(layout);
  };

  const setDisplayConfigButtonValue = () => {
    setDisplayConfigButton(true);
  };

  const loadCurrentProduct = () => {
    setDisplayConfigButtonValue();
    setLoading(true);
    setBreadcrumb([]);

    if (!catalogGroupId) {
      setCurrentProduct(undefined);
    }

    if (catalog && catalog.length > 0) {
      setLoading(false);
    }

    if (catalogGroupId && catalog && catalog?.length > 0) {
      const product: CatalogProduct | undefined = findNestedProduct(catalog, catalogGroupId, setBreadcrumb);
      setCurrentProduct(product);
      setLoading(false);
    }
  };

  const LayoutSwitchButtonStyles: CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "15px",
  };

  // list view root components
  interface CatalogProductRowCardProps {
    catalogGroupID?: string;
    product: CatalogProduct;
    index: number;
    key?: number;
  }

  const CatalogProductRowCard: React.FC<CatalogProductRowCardProps> = ({ product, index }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      //accordion view
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          width: "100%",
          padding: "20px",
          borderRadius: "4px",
          boxShadow: "0px 1px 2px 1px rgb(0,0,0,0.15)",
          ":hover": {
            boxShadow: "0px 2px 10px 0px #00000040",
            "#row-card-title": {
              color: "#CD163F",
            },
          },
        }}>
        <AccordionSummary
          expandIcon={
            <ChevronDownIcon
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            padding: 0,
            display: "flex",
            height: "61px",
            justifyContent: "center",
            gap: "15px",
            width: "100%",
            ".MuiAccordionSummary-content": {
              margin: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              width: "51px",
              height: "80px",
              padding: "27.5px 13px 28.5px 14px",
            },
          }}>
          {product.photo && (
            <CardMedia
              component="img"
              sx={{
                width: "64px",
                maxHeight: "69px",
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              image={documentUrl(product.photo)}
              alt=""
            />
          )}
          <Typography
            variant="h6"
            id="row-card-title"
            sx={{
              fontSize: "24px",
              lineHeight: "28px",
              fontWeight: "bold",
              color: "#2D2D2D",
            }}>
            {product.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            my: "15px",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            gap: "15px",
          }}>
          {product.childGroups.map((children: CatalogProduct, index: number) => (
            <CatalogProductchidlrenRowCard catalogGroupID={product.catalogGroupId} product={children} index={index} key={index} />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const CatalogProductchidlrenRowCard: React.FC<CatalogProductRowCardProps> = ({ catalogGroupID, product, index }) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        disableGutters={true}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
        }}>
        <AccordionSummary
          expandIcon={<ChevronDownIcon sx={{ width: "18px", height: "18px", color: "#000000" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            p: 0,
            minHeight: "32px",
            ".MuiAccordionSummary-content": {
              margin: 0,
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              width: "25px",
              height: "32px",
              padding: "7px 3px 7px 4px",
            },
          }}>
          <Typography
            sx={{
              color: "#2D2D2D",
              lineHeight: "32px",
              fontSize: "24px",
            }}>
            {product.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            my: "15px",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            justifyContent: "flex-start",
            gap: "15px",
          }}>
          {product.templates?.map((templates: CatalogTemplate, index) => {
            return (
              <CatalogTemplateCard
                catalogGroupID={catalogGroupID}
                templates={templates}
                index={index}
                key={index}
                onClick={() => {
                  navigate(`${ROUTES.SEARCH}?tids=${templates.templateId}`);
                  searchNavigation(templates.title);
                }}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  // area for list view components
  const getCatalogProduct = (list: CatalogProduct[] | undefined) => {
    const evenRows: CatalogProduct[] = [];
    const oddRows: CatalogProduct[] = [];

    if (catalog) {
      catalog.forEach((item: CatalogProduct) => {
        if (catalog.indexOf(item) % 2 === 0) {
          evenRows.push(item);
        } else {
          oddRows.push(item);
        }
      });

      return {
        evenRows: evenRows,
        oddRows: oddRows,
      };
    }
  };

  const handleNavigation = (selectionAssistance: boolean, catalogGroupId: string) => {
    const url = window.location.href;
    if (selectionAssistance) {
      navigate(`${ROUTES.FAMILY_SEARCH}/${catalogGroupId}`);
    } else {
      navigate(`${ROUTES.BROWSE}/${catalogGroupId}`);

      const browsePageProducts = new CustomEvent("browseProductSelection", {
        detail: {
          action: "BrowseProduct",
          properties: {
            category: "WebApp",
            label: catalogGroupId,
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(browsePageProducts);
    }
  };

  const searchNavigation = (templateTitle: string) => {
    const searchPageProducts = new CustomEvent("searchProductSelection", {
      detail: {
        action: "SearchProduct",
        properties: {
          category: "WebApp",
          label: templateTitle,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(searchPageProducts);
  };

  const isNormalScreen = useMediaQuery("(min-width: 1105px)");

  interface CatalogTemplateCardProps {
    catalogGroupID?: string;
    templates: CatalogTemplate;
    index: number;
    onClick: () => void;
  }

  const CatalogTemplateCard: React.FC<CatalogTemplateCardProps> = ({ catalogGroupID, templates, index, onClick }) => {
    if (currentLayout === LayoutType.Grid) {
      return (
        <ButtonBase onClick={onClick} sx={{ textAlign: "left", width: "100%" }}>
          <TeaserCard
            raVariant="horizontal"
            key={index}
            sx={{
              display: "flex",
              height: "125px",
              width: "95%",
              overflow: "hidden",
              overflowWrap: "break-word",
              px: 2,
              py: 1,
            }}>
            <CardMedia
              component="img"
              sx={{
                width: 80,
                maxHeight: 100,
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              image={documentUrl(templates.photo)}
              alt=""
              onError={({ currentTarget }: any) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImage;
              }}
            />
            <TeaserCardContent>
              <Box>
                <TeaserCardTitle>{templates.title}</TeaserCardTitle>
              </Box>
              {/* <Button variant='text' onClick={() => handleTemplateDialog(template)}>Learn More</Button> */}
            </TeaserCardContent>
          </TeaserCard>
        </ButtonBase>
      );
    } else {
      return (
        <ButtonBase
          onClick={onClick}
          sx={{
            textAlign: "left",
            width: "100%",
            justifyContent: "flex-start",
          }}>
          <TeaserCard
            raVariant="horizontal"
            key={index}
            sx={{
              display: "flex",
              height: "112px",
              width: "90%",
              p: 2,
              borderRadius: "4px",
              overflow: "hidden",
              overflowWrap: "break-word",
              gap: "10px",
              boxShadow: "0px 1px 5px 0px rgb(0,0,0,0.25)",
              ":hover": {
                boxShadow: "0px 2px 10px 0px #00000040",
                ".teaser-card-title": {
                  color: "#CD163F",
                },
              },
              ".MuiCardContent-root:last-child": {
                pb: 0,
              },
            }}
            hover={false}>
            <CardMedia
              component="img"
              sx={{
                width: "80px",
                height: "80px",
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              image={documentUrl(templates.photo)}
              alt=""
              onError={({ currentTarget }: any) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImage;
              }}
            />
            <TeaserCardContent
              sx={{
                p: 0,
                width: "100%",
                display: "flex",
                gap: "44px",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <TeaserCardTitle
                sx={{
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "32px",
                  m: 0,
                  color: "#2D2D2D",
                }}>
                {templates.title}
              </TeaserCardTitle>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FeatureFlag feature={FEATURES.PI26_BP02_CONFIGURATOR_ENABLED}>
                  {templates.configurationType === "True" && displayConfigButton && (
                    <ConfiguratorButton key={index} templateID={templates.templateId} />
                  )}
                </FeatureFlag>
                <Box
                  sx={{
                    width: "51px",
                    height: "80px",
                    padding: "0px 17px 0px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <ChevronDownIcon
                    sx={{
                      color: "#2D2D2D",
                      width: "18px",
                      height: "18px",
                      transform: "rotate(-90deg)",
                    }}
                  />
                </Box>
              </Box>
              {/* <Button variant='text' onClick={() => handleTemplateDialog(template)}>Learn More</Button> */}
            </TeaserCardContent>
          </TeaserCard>
        </ButtonBase>
      );
    }
  };

  interface CatalogProductCardProps {
    product: CatalogProduct;
    index: number;
    onClick: () => void;
  }
  const CatalogProductCard: React.FC<CatalogProductCardProps> = ({ product, index, onClick }) => {
    return (
      <ButtonBase onClick={onClick} sx={{ textAlign: "left", width: "100%" }}>
        <TeaserCard
          raVariant="horizontal"
          key={index}
          sx={{
            display: "flex",
            height: "275px",
            width: "100%",
            px: 2,
            py: 1,
          }}>
          {product.photo && (
            <CardMedia
              component="img"
              sx={{
                width: 80,
                maxHeight: 100,
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              image={documentUrl(product.photo)}
              alt=""
            />
          )}
          <TeaserCardContent>
            <TeaserCardTitle>{product.title}</TeaserCardTitle>
            {/* <Button variant='text' onClick={() => handleProductDialog(product)}>Learn More</Button> */}
            <TeaserCardSubtitle
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 7,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textTransform: "none",
                fontSize: "1rem",
              }}>
              {product.description}
            </TeaserCardSubtitle>
          </TeaserCardContent>
        </TeaserCard>
      </ButtonBase>
    );
  };

  interface CatalogProductRootCardProps {
    product: CatalogProduct;
    index: number;
    onClick: () => void;
  }

  const CatalogProductRootCard: React.FC<CatalogProductRootCardProps> = ({ product, index, onClick }) => {
    return (
      <ButtonBase onClick={onClick} sx={{ width: "100%" }}>
        <TeaserCard
          raVariant="vertical"
          key={index}
          sx={{
            display: "flex",
            height: "275px",
            width: "100%",
          }}
          variant="elevation">
          {product.photo && (
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                maxHeight: "150px",
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              image={documentUrl(product.photo)}
              alt=""
            />
          )}

          <TeaserCardContent>
            <TeaserCardTitle component="div" variant="h6">
              {product.title}
            </TeaserCardTitle>
            {/* <Button variant='text' onClick={() => handleProductDialog(product)}>Learn More</Button> */}
          </TeaserCardContent>
        </TeaserCard>
      </ButtonBase>
    );
  };

  useEffect(() => {}, [currentProduct]);

  if (isNormalScreen) {
    return (
      <>
        <Masthead />

        <Container sx={{ my: 0, py: 3 }}>
          {!currentProduct && <SearchBreadcrumbs loading={loading} />}
          {currentProduct !== undefined && (
            <SearchBreadcrumbs loading={loading} searchCriteriaGroupTids={breadcrumb} currentProduct={currentProduct} />
          )}
        </Container>

        <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
          <Container sx={{ my: 3 }}>
            <SearchForm />
          </Container>
        </Box>

        <Container sx={{ my: 0, py: 3, backgroundColor: "white", borderRadius: "4px" }}>
          {loading && <>loading...</>}
          {!loading && currentProduct && (
            <>
              <Box sx={{ p: 2, mb: 5 }}>
                <Typography variant="h4" component="h3" fontWeight="bold" sx={{ mb: 3 }}>
                  {currentProduct.title}
                </Typography>
                <Typography variant="subtitle1">{currentProduct.description}</Typography>
              </Box>
            </>
          )}
          {!loading && !currentProduct && (
            <>
              <Box sx={{ p: 2 }}>
                <Typography variant="h4" component="h3" fontWeight="bold" sx={{ mb: 3 }}>
                  Product Families
                </Typography>

                <LayoutSwitchButton currentLayout={currentLayout} handleLayoutChange={handleLayoutChange} sx={LayoutSwitchButtonStyles} />
              </Box>
            </>
          )}

          {!loading && (
            <Grid container direction={"row"} rowGap={6} textAlign="center">
              {getCatalogProduct([]) && (
                <>
                  {currentProduct?.childGroups
                    .reduce((rows, key, index) => {
                      //@ts-ignore
                      return (
                        (index % 2 === 0
                          ? //@ts-ignore
                            rows.push([key])
                          : //@ts-ignore
                            rows[rows.length - 1].push(key)) && rows
                      );
                    }, [])
                    .map((row: CatalogProduct[], index) => {
                      return (
                        <Grid container key={index} flexDirection="row" justifyContent="space-between" rowSpacing={4} columnSpacing={4}>
                          {row.map((product: CatalogProduct, index) => {
                            return (
                              <Grid item md={6} key={index}>
                                <CatalogProductCard
                                  product={product}
                                  index={index}
                                  onClick={() => handleNavigation(product.selectionAssistanceAvailable === "True", product.catalogGroupId)}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      );
                    })}

                  {currentProduct?.templates
                    .reduce((rows, key, index) => {
                      //@ts-ignore
                      return (
                        (index % 2 === 0
                          ? //@ts-ignore
                            rows.push([key])
                          : //@ts-ignore
                            rows[rows.length - 1].push(key)) && rows
                      );
                    }, [])
                    .map((row: CatalogTemplate[], index) => {
                      return (
                        <Grid container flexDirection="row" justifyContent="space-between" rowSpacing={4} columnSpacing={4} key={index}>
                          {row.map((templates: CatalogTemplate, index) => {
                            return (
                              <Grid item md={6} key={index}>
                                <CatalogTemplateCard
                                  templates={templates}
                                  index={index}
                                  key={index}
                                  onClick={() => {
                                    navigate(`${ROUTES.SEARCH}?tids=${templates.templateId}`);
                                    searchNavigation(templates.title);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      );
                    })}

                  {!currentProduct &&
                    catalog &&
                    currentLayout === LayoutType.Grid &&
                    catalog
                      .reduce((rows, key, index) => {
                        //@ts-ignore
                        return (
                          (index % 4 === 0
                            ? //@ts-ignore
                              rows.push([key])
                            : //@ts-ignore
                              rows[rows.length - 1].push(key)) && rows
                        );
                      }, [])
                      .map((row: CatalogProduct[], index) => {
                        return (
                          <Grid container spacing={{ md: 4, lg: 8 }} flexDirection="row" justifyContent="space-between" key={index}>
                            {row.map((product: CatalogProduct, index) => {
                              return (
                                <Grid item md={3} key={index}>
                                  <CatalogProductRootCard
                                    product={product}
                                    index={index}
                                    key={index}
                                    onClick={() => handleNavigation(product.selectionAssistanceAvailable === "True", product.catalogGroupId)}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        );
                      })}

                  {!currentProduct && catalog && currentLayout === LayoutType.List && (
                    <Grid container gap={"20px"} flexDirection="row" justifyContent="space-between">
                      {catalog.map((product: CatalogProduct, index: number) => {
                        return (
                          <Grid key={index} item xs={12} sx={{ padding: 0 }}>
                            <CatalogProductRowCard product={product} index={index} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        </Container>

        <ScrollToTop />
      </>
    );
  } else {
    return (
      <>
        <Masthead />

        <Container sx={{ my: 0, py: 3 }}>
          {!currentProduct && <SearchBreadcrumbs loading={loading} />}
          {currentProduct !== undefined && <SearchBreadcrumbs loading={loading} searchCriteriaGroupTids={catalog} currentProduct={currentProduct} />}
        </Container>

        <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
          <Container sx={{ my: 3 }}>
            <SearchForm />
          </Container>
        </Box>

        <Container sx={{ my: 0, py: 3, backgroundColor: "white", borderRadius: "4px" }}>
          {!loading && currentProduct && (
            <>
              <Box sx={{ p: 2, mb: 5 }}>
                <Typography variant="h4" component="h3" fontWeight="bold" sx={{ mb: 3 }}>
                  {currentProduct.title}
                </Typography>
                <Typography variant="subtitle1">{currentProduct.description}</Typography>
              </Box>
            </>
          )}
          {!loading && !currentProduct && (
            <>
              <Box sx={{ p: 2, mb: 5 }}>
                <Typography variant="h4" component="h3" fontWeight="bold" sx={{ mb: 3 }}>
                  Product Families
                </Typography>
              </Box>
              <LayoutSwitchButton currentLayout={currentLayout} handleLayoutChange={handleLayoutChange} sx={LayoutSwitchButtonStyles} />
            </>
          )}
          {loading && <>loading...</>}
          {!loading && (
            <Grid container direction={"row"} spacing={2}>
              <Grid item sm={12}>
                {currentProduct && (
                  <>
                    {currentProduct.childGroups.map((product: CatalogProduct, index: number) => {
                      return (
                        <CatalogProductCard
                          product={product}
                          index={index}
                          key={index}
                          onClick={() => handleNavigation(product.selectionAssistanceAvailable === "True", product.catalogGroupId)}
                        />
                      );
                    })}
                    {currentProduct.templates.map((templates: CatalogTemplate, index: number) => {
                      return (
                        <CatalogTemplateCard
                          templates={templates}
                          index={index}
                          key={index}
                          onClick={() => {
                            navigate(`${ROUTES.SEARCH}?tids=${templates.templateId}`);
                            searchNavigation(templates.title);
                          }}
                        />
                      );
                    })}
                  </>
                )}
                {!currentProduct &&
                  currentLayout === LayoutType.Grid &&
                  catalog?.map((product: CatalogProduct, index: number) => {
                    return (
                      <CatalogProductCard
                        product={product}
                        index={index}
                        key={index}
                        onClick={() => handleNavigation(product.selectionAssistanceAvailable === "True", product.catalogGroupId)}
                      />
                    );
                  })}

                {!currentProduct &&
                  catalog &&
                  currentLayout === LayoutType.List &&
                  catalog?.map((product: CatalogProduct, index: number) => {
                    return <CatalogProductRowCard product={product} key={index} index={index} />;
                  })}
              </Grid>
            </Grid>
          )}
        </Container>

        <ScrollToTop />
      </>
    );
  }
};

export default Browse;
